import * as React from 'react'
import Seo from '../../components/SEO'
import YoutubeEmbed from '../../components/YoutubeEmbed'

const BookThanks = () => {
  return (
    <>
      <Seo
        title="Book for call"
        description=' The first call is 15 minutes long and focuses on your situation and your goals. It"s free and there"s no sales pitch.'
      />
      <main className="w-full">
        <div className="flex flex-col items-center h-auto">
          {/* hero section */}
          <div className="w-full flex px-4 pt-8 md:pt-16 pb-8 md:px-24 md:pb-8 flex-col items-center justify-center relative">
            <h1 className="typo-h2 text-3xl sm:text-4xl xl:text-5xl text-center  font-semibold leading-relaxed">
              Booking
              <span className="underline-highlight"> Confirmed</span>
            </h1>
            <p className="typo-small-2 text-center max-w-2xl xl:text-lg mt-3 p-0">
              You booked a call, that's great! Please watch this video to make
              sure you understand our process.
            </p>

            <div className="mt-8">
              <YoutubeEmbed embedId="gYuCKmM2yNE" />
            </div>
          </div>

          {/* Calendly */}
          <section className="flex flex-col justify-center m-8 md:m-0 md:mt-0 w-full max-w-5xl">
            <span className="underline-highlight w-28 m-auto mb-7 h-8"></span>
          </section>
        </div>
      </main>
    </>
  )
}

export default BookThanks
